import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout-2";
import SEO from "../components/seo";
import useHorizontal from "@oberon-amsterdam/horizontal/hook";
import useWindowSize from "../hooks/useWindowSize";
import MenuDrawer from "../components/atoms/menuDrawer";

function Magamrol() {
  const data = useStaticQuery(graphql`
    query Magamrol {
      cloudinaryMedia(tags: {eq: "self"}) {
        secure_url
      }
    }
  `);

  const clImage = data.cloudinaryMedia.secure_url;

  const [container, setContainer] = useState();
  useHorizontal({ container: container });

  const { width } = useWindowSize();
  const breakpoint = 1024;

  return (
    <Layout>
      <SEO
        keywords={[
          `épület`,
          `enteriőr`,
          `ingatlan`,
          `iroda`,
          `lakás`,
          `építészeti`,
          `fotó`,
          `fotós`,
          `fotográfus`,
        ]}
        title="Magamról | GÁBOR TAMÁS"
      />
      {width > breakpoint ? (
        // CALC HEIGHT OF HORIZONTAL SCROLL TRACK
        <div
          ref={(ref) => {
            setContainer(ref);
          }}
          className="flex p-0 m-0"
          style={{ height: `calc(100vh - 192px)` }}
        >
          {/* WRAPPER TO POSITON BLOCKS TO MIDDLE */}
          <div className="flex flex-row my-auto">
            {/* CONTENT BLOCK */}
            <div className="flex items-start max-w-3xl ml-32 mr-20 min-w-3/5 xl:min-w-6/12 2xl:min-w-6/12 3xl:min-w-4/12">
              <h1 className="text-3xl font-black text-black md:text-5xl xl:text-5xl 2xl:text-6xl">
              Hiszem, hogy az épületek és belső terek funkcióját és annak egyedülálló értékét, a szakértelemmel megtervezett és elkészített fotográfia képes legjobban bemutatni.
              </h1>
            </div>
            <div className="flex items-center mr-20 3xl:-mt-16 min-w-1/3 2xl:min-w-4/12 3xl:min-w-3/12">
              <img
                className="block object-cover w-100"
                loading="lazy"
                src={clImage}
              />
            </div>
            <div className="flex flex-col items-start max-w-2xl pt-4 mr-4 min-w-2/5 xl:min-w-4/12 2xl:min-w-2/6 3xl:min-w-1/5">
              <h2 className="mb-8 text-xl font-bold text-black 3xl:text-2xl">
                Bemutatkozás
              </h2>
              <p className="mb-8 font-normal leading-7 text-black md:text-xl xl:leading-8 lg:leading-8 lg:text-xl 2xl:leading-10 xl:text-lg 2xl:text-xl">
                Gábor Tamás vagyok Budapest székhelyű, szabadúszó fotográfus. Közel 5 éve készítek képeket épületekről, enteriőrökről, turisztikai desztinációkról. Külföldön, Wales fövárosában Cardiff-ban töltött éveim során ismerkedtem meg a fotózással, amit hagyományos oktatás nélkül, atutodidakta módon az amerikai és angol fotóipart követve sajátítottam el. <br/>4 éve élek újra Budapesten, pályafutásom idehaza Airbnb Plus partner fotósként indult, majd turisztikai stock fotó gyártással foglalkoztam, jelenleg pedig épület, ingatlan és enteriőr képeket készítek magánszemélyeknek és cégeknek.
              </p>
              {/* <p className="text-xl font-normal leading-7 text-black xl:leading-8 lg:leading-8 lg:text-xl 2xl:leading-10 xl:text-lg 2xl:text-xl">
                Főleg Budapesten vagyok elérhető, de szívesen fogadok megkereséseket országszerte és nemzetközi színten egyaránt.
              </p> */}
            </div>
            <div className="flex flex-col items-start max-w-2xl pt-4 ml-16 mr-32 min-w-2/5 xl:min-w-1/3 2xl:min-w-2/5 3xl:min-w-1/5">
              <h2 className="mb-8 text-xl font-bold text-black 3xl:text-2xl">
                Miben lehetek a segítségére?
              </h2>
              <p className="mb-8 font-normal leading-7 text-black md:text-xl xl:leading-8 lg:leading-8 lg:text-xl 2xl:leading-10 xl:text-lg 2xl:text-xl">
                Szívesen vállalom magán, kereskedelmi vagy ipari ingatlanok fényképezését, továbbá örömmel támogatom, építészek, lakberendezők, kivitelezők és ingatlanközvetítők projektjeit. Képeimet weboldalakon, közösségi médiában, brossúrákban és a marketing minden egyéb formájában felhasználják nemzetközileg. <br/>
                A fényképezés komolyabb projeketek esetében megelőző bejárással/terepszemlével és tervezéssel kezdődik. Egyszerűbb megbízások esetében, fotózás napján helyben gyors egyzettetéssel. 
                Magam végzem a képek digitális utómunkáját, ezáltal minden projektem egységes, garantált az &ldquo;on-brand&ldquo; konzisztens képminőség.
              </p>
            </div>
            <div style={{minWidth: `1px`}}></div>
          </div>
        </div>
      ) : (
        <div className="p-0 m-0">
        {/* WRAPPER TO POSITON BLOCKS TO MIDDLE */}
        <div className="mx-4 mt-10 mb-6 sm:mx-12">
            {/* CONTENT BLOCK */}
              <h1 className="mb-6 text-3xl font-black leading-snug text-black md:mb-10 md:text-5xl xl:text-5xl 2xl:text-6xl">
              Hiszem, hogy az épületek és belső terek funkcióját és annak egyedülálló értékét, a szakértelemmel megtervezett és elkészített fotográfia képes legjobban bemutatni.
              </h1>
              <div className="flex items-start mb-6 md:mb-10">
                <img
                  className="block object-cover w-full my-6"
                  loading="lazy"
                  src={clImage}
                />
              </div>
              <h2 className="mb-4 text-xl font-bold text-black 3xl:te xt-2xl">
                Bemutatkozás
              </h2>
              <p className="mb-8 font-normal leading-7 text-black md:mb-10 md:text-xl xl:leading-8 lg:leading-8 lg:text-xl 2xl:leading-10 xl:text-lg 2xl:text-xl">
              Gábor Tamás vagyok Budapest székhelyű, szabadúszó fotográfus. Közel 5 éve készítek képeket épületekről, enteriőrökről, turisztikai desztinációkról. Külföldön, Wales fövárosában Cardiff-ban töltött éveim során ismerkedtem meg a fotózással, amit hagyományos oktatás nélkül, atutodidakta módon az amerikai és angol fotóipart követve sajátítottam el. <br/>4 éve élek újra Budapesten, pályafutásom idehaza Airbnb Plus partner fotósként indult, majd turisztikai stock fotó gyártással foglalkoztam, jelenleg pedig épület, ingatlan és enteriőr képeket készítek magánszemélyeknek és cégeknek.
              </p>
              <h2 className="mb-4 text-xl font-bold text-black 3xl:text-2xl">
                Miben lehetek segítségedre?
              </h2>
              <p className="mb-8 font-normal leading-7 text-black md:text-xl xl:leading-8 lg:leading-8 lg:text-xl 2xl:leading-10 xl:text-lg 2xl:text-xl">
              Szívesen vállalom magán, kereskedelmi vagy ipari ingatlanok fényképezését, továbbá örömmel támogatom, építészek, lakberendezők, kivitelezők és ingatlanközvetítők projektjeit. Képeimet weboldalakon, közösségi médiában, brossúrákban és a marketing minden egyéb formájában felhasználják nemzetközileg. <br/>
                A fényképezés komolyabb projeketek esetében megelőző bejárással/terepszemlével és tervezéssel kezdődik. Egyszerűbb megbízások esetében, fotózás napján, helyben történik gyors egyzettetéssel. 
                Magam végzem a képek digitális utómunkáját, ezáltal minden projektem egységes, garantált az &ldquo;on-brand&ldquo; konzisztens képminőség.
              </p>
          </div>
        </div>
      )}
      <MenuDrawer/>
    </Layout>
  );
}

export default Magamrol;
